<template>
  <div class="app-box">
    <HomeNavigationBarPage :nav-data="pageData.navs" data-aos="fade-up" />
    <div class="content1-warp ">
      <div class="title-wrapper">
        <video class="video" src="https://minio.tduckcloud.com/landing/video.m4v" autoplay loop muted />
        <div class="typewriter">
          <p class="text-center">
            {{ mainTitle }}
          </p>
        </div>
        <div class="title-wrapper-content">
          {{ pageData.contentOne.content }}
        </div>
        <div class="wrapper-content-tag">
          <n-button
            v-for="item in pageData.contentOne.buttonList"
            :key="item.link"
            size="large"
            tag="a"
            target="_blank"
            :href="item.link"
            class="wrapper-content-tag-item"
            :color="item.buttonColor"
          >
            {{ item.label }}
            <n-badge v-if="item.new" class="mb30" value="新" color="red" />
          </n-button>
        </div>
        <div class="wrapper-content-card">
          <a v-for="item in pageData.contentOne.cardList" :key="item.link" :href="item.link" target="_blank" class="card-link">
            <n-card :title="item.title" class="card-box">
              <template #cover>
                <img class="card-img" :src="item.img">
              </template>
              {{ item.content }}</n-card>
          </a>
        </div>
      </div>
    </div>
    <div class="contact-us" @mouseover="showImage" @mouseleave="hideImage">
      <a>在线咨询</a>
      <img width="200" src="https://oss.tduckcloud.com/tduckpro/c4ca4238a0b923820dcc509a6f75849b/8713208cc4f245deabd0d4d5fec76542.png" class="contact-image">
    </div>
    <div class="survey-btn" @click="droptoSurvey">
      <a>获取方案</a>
    </div>

    <HomeContenTwoIndex class="customer" :conten-two-data="pageData.contenTwo" data-aos="fade-up" />
    <HomeFooterOne :footer-data="pageData.footer" data-aos="fade-up" />
  </div>
</template>

<script setup lang="ts">
function droptoSurvey () {
  window.open('https://x.tduckcloud.com/s/JoEp8lVR')
}

useHead({
  title: 'TDUCK填鸭表单-官网',
  meta: [
    { name: 'description', content: '问卷调查,调查问卷,满意度调查,市场调查,报名登记表,填鸭问卷' }
  ]
})
useSeoMeta({
  title: '专业问卷系统-填鸭表单满足您的调研需求',
  ogTitle: 'TDUCK填鸭表单',
  keywords: '私有化问卷系统,表单设计工具,数据采集平台,私有化部署表单系统,开源问卷源码,企业问卷管理,流程管理系统,调研工具,数据分析平台,考试管理系统,工单管理,开源表单工具,免费表单生成器',
  description: '填鸭表单是一款私有化部署的开源问卷调查系统，基于Springboot+Vue，提供高效的数据收集与管理解决方案。适用于企业私有化部署，支持流程管理，问卷分析，数据采集与发布，提供灵活的系统集成方案。',
  ogDescription: '填鸭表单是一个基于Springboot+Vue的开源问卷调查系统，支持多种数据采集渠道，适用于企业级私有化部署，提供便捷的问卷创建、发布、管理和分析服务，是企业信息采集和流程管理的好帮手。'
})

const pageData = {
  navs: {
    logoImg: 'https://minio.tduckcloud.com/landing/bb326f9678cb4874b0a5e53b186ded0d.png',
    logoLink: '/',
    menus: [
      {
        label: '文档中心',
        link: 'https://doc.tduckcloud.com/'
      },
      {
        label: '产品列表',
        // link: 'https://gitee.com/TDuckApp/tduck-platform',
        options: [
          {
            label: 'TDuck - 社区版',
            key: 'marina bay sands',
            props: {
              onClick: () => {
                window.open('https://gitee.com/TDuckApp/tduck-platform')
              }
            }
            // disabled: true
          },
          {
            label: 'TDuckPro-Vue2版',
            props: {
              onClick: () => {
                window.open('/pro')
              }
            }
          },
          {
            label: 'TDuckX-Vue3版',
            props: {
              onClick: () => {
                window.open('/x')
              }
            }
          },
          {
            label: 'TReport - 数据大屏',
            props: {
              onClick: () => {
                window.open('https://gitee.com/TDuckApp/tduck-report-platform')
              }
            }
          },
          {
            label: 'nium - 牛码二维码',
            props: {
              onClick: () => {
                window.open('https://nium.top')
              }
            }
          }
        ]
      },
      {
        label: '商业版定价',
        options: [
          {
            label: 'TDuckPro-Vue2版',
            key: 'marina bay sands',
            props: {
              onClick: () => {
                window.open('https://www.tduckcloud.com/pro#pricing-section')
              }
            }
            // disabled: true
          },
          {
            label: 'TDuckX-Vue3版',
            props: {
              onClick: () => {
                window.open('https://www.tduckcloud.com/x#pricing-section')
              }
            }
          }
        ]
      },
      {
        label: '开源地址',
        link: 'https://gitee.com/TDuckApp/tduck-platform',
        blank: true // false 不跳转
      },
      {
        label: '用户案例',
        link: 'case/list',
        blank: false,
        new: false
      },
      {
        label: 'TDuckX',
        link: '/x',
        blank: false,
        new: true
      },
      {
        label: '宝塔面板',
        link: '/bt',
        blank: true,
        new: true
      }
    ]
  },
  contentOne: {
    titleList: [
      '开箱即用的填鸭表单',
      '为每个先进组织提效'
    ],
    content: '',
    linkTextList: [
      {
        label: '典型用户 >',
        link: 'https://doc.tduckcloud.com/buyPro/customer.html'
      },
      {
        label: '🔥功能对比清单 >',
        link: 'https://docs.qq.com/sheet/DSUhoR2pOc2RuZ0Va?tab=BB08J2'
      }
    ],
    buttonList: [
      {
        label: '社区版一键安装',
        link: '/bt',
        buttonColor: '#2770F5'
      },
      {
        label: 'TDuckPro-Vue2 版',
        link: '/pro',
        buttonColor: '#2770F5'
      },
      {
        label: 'TDuckX-Vue3 版',
        link: '/x',
        buttonColor: '#2770F5',
        new: false
      }
    ],
    cardList: [{
      title: '填鸭表单×宝塔面板', content: '联合宝塔面板，高倍繁琐步骤，一键部署表单应用。', img: 'https://minio.tduckcloud.com/landing/b2.png', link: '/bt', color: 'red'
    }, {
      title: 'TDuckX1.8更新介绍', content: '优化表单数据暂存、新增填写任务、优化账号安全...', img: 'https://doc-oss.tduckcloud.com/doc/change1-8.png', link: 'https://www.bilibili.com/video/BV1C4p2eHE7E/?share_source=copy_web&vd_source=1697cba9dbdc63cdd1e1d35bc59a2eb0', color: 'red'
    }, {
      title: '使用案例征集🔥', content: '尊敬的填鸭表单用户，感谢您一直以来对填鸭表单的支持与信任。', img: 'https://doc-oss.tduckcloud.com/doc/keshihua@1x%20(1).png', link: '/case/list', color: 'red'
    }, {
      title: '填鸭系列产品功能清单', content: '点击查看TDuck系列产品的功能清单，能力差异一目了然！', img: 'https://minio.tduckcloud.com/landing/images/qingdan.png', link: 'https://docs.qq.com/sheet/DSUhoR2pOc2RuZ0Va?tab=BB08J2', color: 'red'
    }]

  },
  contenTwo: {
    title: 'Ta们都在使用填鸭表单',
    linkTextList: {
      label: '',
      link: 'https://doc.tduckcloud.com/buyPro/customer.html'
    },
    imagesList: [
      { images: 'https://minio.tduckcloud.com/landing/icons/shiyou.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/yidong.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/liantong.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/dianxin.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/qiaqia.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/gjyl.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/dji.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/geely.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/lingxi.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/Hisense1.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/zhongkeyuan.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/tengxunyinyu.png' },
      // 教育用户
      { images: 'https://minio.tduckcloud.com/landing/icons/xianjiaoda.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/wuhandaxue.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/qinghua.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/guangzhoudaxue.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/hdsfdx.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/bjjd.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/scdx.png' },
      // 制造
      { images: 'https://minio.tduckcloud.com/landing/icons/bosch.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/foxconn.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/jmj.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/xinshidai.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/sfc.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/itg.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/haier.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/991.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/sichuanjichang.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/zgrs@1x.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/cryl.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/yfm.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/zhjt.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/zgbq.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/saj.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/sdgs.png' },
      // { images: 'https://minio.tduckcloud.com/landing/icons/tfws.png' },
      // { images: 'https://minio.tduckcloud.com/landing/icons/zjsh.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/hdrm.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/gjdszj.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/tyszf.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/xmszf.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/Nikon.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/dzqc.png' },
      { images: 'https://minio.tduckcloud.com/landing/icons/Porsche.png' }
    ]
  },
  footer: {
    logoUrl: 'https://minio.tduckcloud.com/landing/bb326f9678cb4874b0a5e53b186ded0d.png',
    description: '',
    footerList: [
      {
        title: '产品列表',
        linkTextList: [{
          label: '社区开源版',
          link: 'https://gitee.com/TDuckApp'
        },
        {
          label: 'TDuckPro-Vue2版',
          link: 'https://doc.tduckcloud.com/buyPro/'
        },
        {
          label: 'TDuckX-Vue3版',
          link: 'https://www.tduckcloud.com/x'
        }, {
          label: 'TReport数据大屏',
          link: 'https://gitee.com/TDuckApp/tduck-report-platform'
        },
        {
          label: 'nium牛码二维码',
          link: 'https://nium.top'
        }
        ]
      },
      {
        title: '关于我们',
        linkTextList: [{
          label: '公益组织支持计划',
          link: 'https://x.tduckcloud.com/s/7wzfTiuG'
        }, {
          label: 'Tduck-Life',
          link: 'https://space.bilibili.com/409825300'
        },
        {
          label: '使用条款',
          link: 'https://doc.tduckcloud.com/blog/userAgreement.html'
        },
        {
          label: '免责声明',
          link: 'https://doc.tduckcloud.com/buyPro/disclaimer.html'
        }
        ]
      },
      {
        title: '联系我们',
        linkTextList: [{
          label: '咨询作者',
          link: 'https://pro.tduckcloud.com/s/eOJT6IEA'
        },
        {
          label: 'mama@tduckapp.com',
          link: '#'
        },
        {
          label: '文档中心',
          link: 'https://doc.tduckcloud.com/'
        }
        ]
      },
      {
        title: '友情链接',
        linkTextList: [{
          label: 'Pig微服务',
          link: 'https://www.pig4cloud.com/'
        },
        {
          label: '交换友链',
          link: 'https://x.tduckcloud.com/s/3c1CjVYX'
        }

        ]
      }
    ],
    images: 'https://minio.tduckcloud.com/landing/images/serviceQrCode.png',
    cooperate: '合作伙伴：<a href="https://www.bt.cn/u/fwvsEi" target="_blank"><img src="https://www.bt.cn/developer/images/logo.png" style="width: 60px"> </a>宝塔服务器面板，一键全能部署及管理 ',
    location: 'Copyright © 2019 - 2024 TDuckCloud. 湖南省众达数蔚信息技术有限公司- <a target="_blank" style="color: #1890ff" href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备19005498号-3 </a>&nbsp; All Rights Reserved.填鸭表单版权所有'
  }
}

const mainTitle = ref('')
let index = 0
const text = '开箱即用的填鸭表单\n为每个先进组织提效'

function type () {
  if (index < text.length) {
    mainTitle.value += text.charAt(index)
    index++
    setTimeout(type, 80)
  }
}

onMounted(() => {
  type()
})

</script>

<style scoped lang="scss">
@media (max-width: 767px) {
  .video{
    display: none;
  }
  .content1-warp {
    width: 100%;
    height: 1900px !important;
  }
  .content1-warp h1 {
    width: 100%;
    font-size: 34px !important;
  }
  .wrapper-content-tag{
    display: flex;
    flex-direction: column;
  }

  .wrapper-content-tag-item{
    margin: 5px!important;
  }

  .title-wrapper-content {
    font-size: 14px !important;
    letter-spacing: 0 !important;
  }
  .wrapper-content-card {
    margin-top: 10px !important;
    display: flex;
    flex-direction: column;

  }
  .card-box{
    border-radius: 10px;
    margin:10px;
  }
  .typewriter {
    display: inline-block;
    overflow: hidden;
    white-space: pre-wrap; /* Allows for line breaks */
    margin: 0 auto;
    letter-spacing: .15em;
    animation: blink-caret .75s step-end infinite;
    width: 100%; /* Optional: adjust the width as needed */
    font-size: 30px !important;
    font-weight: 700;
    z-index: 2;
  }
}

.video{
  position: absolute;
  top: 0;
  z-index: 0;
  opacity: 0.4;
}

.contact-us {
  border-radius: 20px;
  position: fixed; /* 将按钮固定在页面上 */
  right: 50px; /* 调整按钮到右边的距离 */
  bottom: 80px; /* 调整按钮到底部的距离 */
  background-color: #ffffff; /* 设置按钮背景颜色 */
  color: #2770F5; /* 设置按钮文字颜色 */
  padding: 10px 20px; /* 设置按钮内边距 */
  text-decoration: none; /* 取消链接的下划线 */
  box-shadow: 0 0 10px #2770F5; /* 添加阴影效果 */
  opacity: 0;
  animation: blink 0.8s infinite alternate; /* 添加闪烁动画 */
}

.survey-btn{
  border-radius: 20px;
  position: fixed; /* 将按钮固定在页面上 */
  right: 50px; /* 调整按钮到右边的距离 */
  bottom: 20px; /* 调整按钮到底部的距离 */
  background-color: #ffffff; /* 设置按钮背景颜色 */
  color: #2770F5; /* 设置按钮文字颜色 */
  padding: 10px 20px; /* 设置按钮内边距 */
  text-decoration: none; /* 取消链接的下划线 */
  box-shadow: 0 0 10px #2770F5; /* 添加阴影效果 */
  opacity: 0;
  animation: blink 0.8s infinite alternate; /* 添加闪烁动画 */
}

.survey-btn :hover{
cursor: pointer;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.contact-us:hover {
  background-color: #ffffff; /* 鼠标悬浮时改变按钮背景颜色 */
  .contact-image {
    display: block; /* 悬浮时显示图片 */
    opacity: 1; /* 渐变显示 */
  }
  opacity: 1;
  animation: blink2 1s infinite alternate; /* 添加闪烁动画 */
}

.contact-image {
  border-radius: 5px;
  width: 100px;
  display: none; /* 默认隐藏图片 */
  position: absolute; /* 绝对定位，使图片覆盖在链接上方 */
  top: -120px; /* 调整图片位置 */
  left: -1px;
  transition: opacity 0.3s ease; /* 添加渐变动画效果 */
}

.content1-warp {
  width: 100%;
  height: 1080px;
  background-image:url(https://minio.tduckcloud.com/landing/bg-img.jpeg) ;
  //background: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .title-wrapper {
    padding-top: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .typewriter {
    display: inline-block;
    overflow: hidden;
    white-space: pre-wrap; /* Allows for line breaks */
    margin: 0 auto;
    letter-spacing: .05em;
    animation: blink-caret .75s step-end infinite;
    width: 100%; /* Optional: adjust the width as needed */
    font-size: 50px;
    font-weight: 700;
    z-index: 1;
  }

  .title-wrapper-content {
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #b6b6b6;
    letter-spacing: 1px;
  }

  .wrapper-content {
    margin: 20px 0;

    a {
      color: #ffffff;
      font-size: 16px;
      margin: 0 5px;
    }
  }
  .wrapper-content-card {
    margin-top: 100px;
    display: flex; /* 使用 Flexbox 布局 */
    justify-content: center; /* 水平居中 */
  }
  .card-box{
    border-radius: 10px;
    margin:20px;
  }
  .card-img{
    margin-top: 10px;
    height: 150px;
  }

  .wrapper-content-tag {
    margin-top: 30px;
    a {
      margin: 0 5px;
      border-radius: 30px;
    }
  }
}

.n-card {
  max-width: 300px;
  box-shadow: 0px 0px 10px 0px rgba(208, 200, 200, 0.5) !important;

}
.card-link {
  text-decoration: none;
}

.carousel-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
</style>
